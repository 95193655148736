import React, { Fragment, lazy, Suspense } from "react";
import Loading from "../Loading/Loading";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import ArtikelTekst from "../Incident/ArtikelTekst";
import ArtikelFoto from "../Incident/ArtikelFoto";

const Titelkaart = lazy(() => import("../Titelkaart/Titelkaart"));

const TeWater = () => {
  return (
    <Fragment>
      <Suspense fallback={<Loading />}>
        <Helmet>
          <title>tis3.nl - treinen te water</title>
          <meta
            name="description"
            content={
              "Treinen rijden op rails, en die rails liggen doorgaans op een droge ondergrond. Soms gaat het echter goed mis en belandt een trein in het water. Op deze pagina een overzicht van de opvallendste treinen die het water haalden en één die zelf als brug eindigde."
            }
          />
        </Helmet>
        <Titelkaart
          titel="TIS3"
          extratekst="treinen te water"
          foto="0n5ZWxz4NpEBfhZNqr0B"
        />
        <div className="Incident">
          <div className="Artikel">
            <h1>Treinen te water</h1>
            <ArtikelTekst
              mode="l"
              content="Treinen rijden op rails, en die rails liggen doorgaans op een droge ondergrond. Soms gaat het echter goed mis en belandt een trein in het water. Op deze pagina een overzicht van de opvallendste treinen die het water haalden en één die zelf als brug eindigde."
            />
            <hr />
            <ArtikelFoto
              content="fSXTaSvsguaKdoPxlULI"
              mode="r"
              toonLink={true}
            />
            <h2>1856: Tegen de Jeremiebrug</h2>
            <ArtikelTekst
              italic={true}
              content="Heden namiddag is de goederen trein, die ten 3 Ure van Utrecht naar Arnhem was vertrokken en waarmede eenige weinige reizigers werden vervoerd, tegen de draaibrug aan de Jeremie, welke men bezig was te sluiten, aangeloopen, met het ongelukkige gevolg dat de locomotief en de tender in het water zijn geraakt, en, dat de gezegde brug dermate is gehavend, dat zij gedurende eenigen tijd onbruikbaar zal zijn."
            />
            <p className="bodytekst">
              artikel: <Link to="/incident/utrecht_1856">Utrecht 1856</Link>
            </p>
            <hr />
            <h2>1899: De loc die het bijna haalde</h2>
            <ArtikelTekst content="In Nieuwerkerk aan den IJssel had de brugwachter zich verslapen, waardoor de eerste trein van de dag een open brug trof. Door de snelheid van de trein, wist de locomotief de overkant te bereiken, maar de rest van de trein viel in het water en trok de loc alsnog mee." />
            <p className="bodytekst">
              artikel:{" "}
              <Link to="/incident/nieuwerkerk_1899">
                Nieuwerbrug aan den IJssel 1899
              </Link>
            </p>
            <hr />
            <h2>1926: Te veel water onder de brug</h2>
            <ArtikelTekst content="In Haps was de ondergrond zo drassig nadat de Maas overstroomd was geweest, dat de spoorbrug over de Raam het gewicht van een pendeltreintje niet meer kon houden. De reizigers kregen een nat pak, maar bleven ongedeerd." />
            <p className="bodytekst">
              artikel: <Link to="/incident/haps_1926">Haps 1926</Link>
            </p>
            <hr />
            <h2>1927: Vissen naar een trein</h2>
            <ArtikelTekst content="Bij Hallum ontspoorde een trein, die daarna de vaart inreed. Een visser die langs de kant zat, werd meegesleurd, maar kon door een collega worden gered. Ook het treinpersoneel werd weer opgevist." />
            <p className="bodytekst">
              artikel: <Link to="/incident/hallum_1927">Hallum 1927</Link>
            </p>
            <hr />
            <ArtikelFoto content="0n5ZWxz4NpEBfhZNqr0B" mode="r" />
            <h2>1949: De drijvende kazen</h2>
            <ArtikelTekst content="In Meppel reed een losgeslagen trein, beladen met kazen van de Frico, door een juk en belandde in de Reest. De drijvende kazen werden tot kilometers verderop nog teruggevonden." />
            <p className="bodytekst">
              artikel:{" "}
              <Link to="/incident/meppel_1949a">Meppel 1949 (april)</Link>
            </p>
            <hr />
            <h2>1987-1988: Twee keer hetzelfde water</h2>
            <ArtikelTekst content="In Veendam reden binnen twee jaar tijd twee locs hetzelfde bruggat van het Oosterdiep in. Beide keren waren de locs onbemand en door een rangeerbeweging de plomp in geduwd." />
            <p className="bodytekst">
              artikels: <Link to="/incident/veendam_1987">Veendam 1987</Link> en{" "}
              <Link to="/incident/veendam_1988">Veendam 1988</Link>
            </p>
            <hr />
            <h2>1980: De trein die een brug nadeed</h2>
            <ArtikelTekst content="In Vlaardingen reed een trein een sluitende spoorbrug op, ontspoorde en kantelde van de brug af. Als door een wonder bleef de trein precies op de landhoofden liggen, waardoor deze zelf een soort brug werd." />
            <ArtikelFoto content="Hgt6baOleL63W41t2E38" />
            <p className="bodytekst">
              artikel:{" "}
              <Link to="/incident/vlaardingen_1980">Vlaardingen 1980</Link>
            </p>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

export default TeWater;
