import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import "normalize.css";
import "../../styles/algemeen.css";

import { IncidentenProvider } from "../../contexts/Incidenten";
import { DeviceWidthContext } from "../../contexts/DeviceWidth";
import { FotoOverlayContext } from "../../contexts/FotoOverlay";
import { UserContext } from "../../contexts/User";

import ShowFotoOverlay from "../FotoOverlay/ShowFotoOverlay";
import { Helmet } from "react-helmet";
import Loading from "../Loading/Loading";
import TeWater from "../Thema/TeWater";

const Navigatie = lazy(() => import("../Navigatie/Navigatie"));
const Zoek = lazy(() => import("../Zoek/Zoek"));
const Admin = lazy(() => import("../../Admin/Admin"));
const Voorpagina = lazy(() => import("../../components/Voorpagina/Voorpagina"));
const Incident = lazy(() => import("../Incident/Incident"));

const Shinen = lazy(() => import("../Thema/Shinen"));

const Provincie = lazy(() => import("../Lijst/Provincie"));
const Type = lazy(() => import("../Lijst/Type"));
const Oorzaak = lazy(() => import("../Lijst/Oorzaak"));

const Statistieken = lazy(() => import("../Statistieken/Statistieken2"));

const App = () => {
  const [, dispatchDeviceWidth] = useContext(DeviceWidthContext);
  const [FotoOverlay] = useContext(FotoOverlayContext);
  const [{ user }] = useContext(UserContext);

  useEffect(() => {
    window.addEventListener("resize", () =>
      dispatchDeviceWidth({
        type: "SET",
        deviceWidth:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
      })
    );
    return window.removeEventListener("resize", null);
  }, [dispatchDeviceWidth]);

  return (
    <Suspense fallback={<Loading />}>
      <IncidentenProvider>
        <Helmet>
          <title>tis3.nl - spoorwegongevallen in Nederland</title>
          <meta
            name="description"
            content="Een overzicht en beschrijving van spoorwegongelukken en spoorwegongevallen in Nederland."
          />
        </Helmet>
        <Navigatie />
        <Switch>
          <Route
            path="/incident/:id"
            render={(props) => (
              <Incident key={props.match.params.id} {...props} />
            )}
          />
          <Route
            path="/provincie/:id"
            render={(props) => (
              <Provincie key={props.match.params.id} {...props} />
            )}
          />
          <Route
            path="/type/:id"
            render={(props) => <Type key={props.match.params.id} {...props} />}
          />
          <Route
            path="/oorzaak/:id"
            render={(props) => (
              <Oorzaak key={props.match.params.id} {...props} />
            )}
          />
          <Route path="/zoek/:query">
            <Zoek />
          </Route>
          <Route path="/statistieken">
            <Statistieken />
          </Route>
          <Route path="/thema/shinen">
            <Shinen />
          </Route>
          <Route path="/thema/tewater">
            <TeWater />
          </Route>
          {user && (
            <Route path="/admin">
              <Admin />
            </Route>
          )}
          <Route>
            <Voorpagina />
          </Route>
        </Switch>
        {FotoOverlay && FotoOverlay.fotoid && <ShowFotoOverlay />}
      </IncidentenProvider>
    </Suspense>
  );
};

export default App;
