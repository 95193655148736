import React, { createContext, useReducer } from "react";

let UserReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      // if (action.user.uid === "Nzg9uqdDuZRjgq30xwF5JsVMCNT2") {
        return { user: action.user };
      // } else {
      //   return { user: null };
      // }
    case "LOGOUT":
      return { user: null };
    default:
      return state;
  }
};
const initialUser = { user: null };
const UserContext = createContext(initialUser);
const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialUser);
  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
