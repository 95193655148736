import React, { createContext, useReducer } from "react";

let DeviceWidthReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return { deviceWidth: action.deviceWidth };
    default:
      return state;
  }
};

const initialDeviceWidth = {
  deviceWidth:
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth,
};
const DeviceWidthContext = createContext(initialDeviceWidth);
const DeviceWidthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DeviceWidthReducer, initialDeviceWidth);
  return (
    <DeviceWidthContext.Provider value={[state, dispatch]}>
      {children}
    </DeviceWidthContext.Provider>
  );
};

export { DeviceWidthContext, DeviceWidthProvider };