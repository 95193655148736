import React from "react";

import "../../styles/algemeen.css";

const ArtikelTekst = ({ mode, content, notice = false, italic = false }) => {
  const cssClass =
    mode === "l"
      ? "leadtekst"
      : mode === "d"
      ? "dropcapbodytekst"
      : "bodytekst";

  return (
    <p
      className={cssClass}
      style={{
        fontStyle: notice || italic ? "italic" : "inherit",
        fontSize: notice ? "0.8em" : "inherit",
      }}
    >
      {notice && (
        <>
          <i className="fas fa-exclamation-triangle" />
          &nbsp;
        </>
      )}
      {content &&
        content.split("\\n").map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item}
              {i + 1 < content.split("\\n").length && <br />}
            </React.Fragment>
          );
        })}
    </p>
  );
};

export default ArtikelTekst;
