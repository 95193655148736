import React, { useContext, useEffect, useState } from "react";
import { db, storage } from "../../firebase/Firebase";

import moment from "moment";

import { DeviceWidthContext } from "../../contexts/DeviceWidth";
import { FotoOverlayContext } from "../../contexts/FotoOverlay";
import { IncidentenContext } from "../../contexts/Incidenten";

import { Link } from "react-router-dom";

import "../../styles/algemeen.css";

const ArtikelFoto = ({ content, mode, bijschrift, thumb, toonLink }) => {
  const incidenten = useContext(IncidentenContext);

  const [url, setUrl] = useState(null);
  const [bronnaam, setBronnaam] = useState(null);
  const [bronurl, setBronurl] = useState(null);
  const [bronurltitel, setBronurltitel] = useState(null);
  const [licentietitel, setLicentietitel] = useState(null);
  const [licentietekst, setLicentietekst] = useState(null);
  const [licentieurl, setLicentieurl] = useState(null);
  const [incident, setIncident] = useState(null);
  const [incidentTitel, setIncidentTitel] = useState(null);
  const [incidentDatum, setIncidentDatum] = useState(null);
  const [{ deviceWidth }] = useContext(DeviceWidthContext);
  const [, dispatchFotoOverlay] = useContext(FotoOverlayContext);

  const cssClass = thumb
    ? "fotoContainerThumb"
    : mode === "l"
    ? "fotoContainerLinks"
    : mode === "r"
    ? "fotoContainerRechts"
    : "fotoContainerVol";

  useEffect(() => {
    let fotoSize = "";
    if (deviceWidth < 2600 && (mode === "r" || mode === "l")) {
      fotoSize = "_800x600";
    } else if (deviceWidth < 3400 && (mode === "r" || mode === "l")) {
      fotoSize = "_1024x768";
    } else if (deviceWidth < 1000 && !mode) {
      fotoSize = "_800x600";
    } else if (deviceWidth < 1280 && !mode) {
      fotoSize = "_1024x768";
    }
    if (thumb) {
      fotoSize = "_80x60";
    }

    db.collection("fotos")
      .doc(content)
      .get()
      .then((doc) => {
        setBronnaam(doc.data().bron_naam || null);
        setBronurl(doc.data().bron_url || null);
        setBronurltitel(doc.data().bron_url_titel || null);
        setLicentietitel(doc.data().licentie_titel || null);
        toonLink && setIncident(doc.data().incident || null);
        return storage.ref(`fotos/${content}${fotoSize}.jpg`).getDownloadURL();
      })
      .then((url) => {
        setUrl(url);
      });
  }, [content, mode, thumb, deviceWidth, toonLink]);

  useEffect(() => {
    if (toonLink && incident && incidenten && incidenten.length > 0) {
      let ditIncident = incidenten.find((o) => o.id === incident);
      if (ditIncident) {
        setIncidentTitel(ditIncident.titel);
        ditIncident.datum &&
          setIncidentDatum(
            moment(ditIncident.datum.toDate()).format("D MMMM YYYY")
          );
      }
    }
  }, [toonLink, incident, incidenten]);

  useEffect(() => {
    licentietitel &&
      db
        .collection("licenties")
        .doc(licentietitel)
        .get()
        .then((doc) => {
          setLicentieurl(doc.data().url || null);
          setLicentietekst(doc.data().titel || licentietitel);
        });
  }, [licentietitel]);

  return url ? (
    <div className={cssClass}>
      <img
        src={url}
        className="foto"
        onClick={() => dispatchFotoOverlay({ type: "SHOW", fotoid: content })}
        alt={bronnaam}
      />
      {toonLink && (
        <span className="leadtekst">
          <Link to={`/incident/${incident}`}>{incidentTitel}</Link>
        </span>
      )}
      {toonLink && incidentDatum && (
        <span className="leadtekst">, {incidentDatum}</span>
      )}
      {toonLink && <br />}
      {!thumb && bijschrift && (
        <>
          <span className="leadtekst">{bijschrift}</span>
          <br />
        </>
      )}
      {!thumb && (
        <>
          ({bronnaam},{" "}
          <a href={bronurl} target="_new">
            {bronurltitel}
          </a>
          ,{" "}
          <a href={licentieurl} target="_new">
            {licentietekst}
          </a>
          )
        </>
      )}
    </div>
  ) : null;
};

export default ArtikelFoto;
