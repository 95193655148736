import React, { createContext, useReducer } from "react";

let FotoOverlayReducer = (state, action) => {
  switch (action.type) {
    case "SHOW":
      return { fotoid: action.fotoid };
    case "HIDE":
      return { fotoid: null };
    default:
      return state;
  }
};

const initialFotoOverlay = null;
const FotoOverlayContext = createContext(initialFotoOverlay);
const FotoOverlayProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FotoOverlayReducer, initialFotoOverlay);
  return (
    <FotoOverlayContext.Provider value={[state, dispatch]}>
      {children}
    </FotoOverlayContext.Provider>
  );
};

export { FotoOverlayContext, FotoOverlayProvider };
