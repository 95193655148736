import React, { useContext, useEffect, useState } from "react";
import { db, storage } from "../../firebase/Firebase";
import { FotoOverlayContext } from "../../contexts/FotoOverlay";

import "../../styles/algemeen.css";

const ShowFotoOverlay = () => {
  const [FotoOverlay, dispatchFotoOverlay] = useContext(FotoOverlayContext);

  const [url, setUrl] = useState(null);
  const [bronnaam, setBronnaam] = useState(null);
  const [bronurl, setBronurl] = useState(null);
  const [bronurltitel, setBronurltitel] = useState(null);
  const [licentietitel, setLicentietitel] = useState(null);
  const [licentietekst, setLicentietekst] = useState(null);
  const [licentieurl, setLicentieurl] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    FotoOverlay.fotoid &&
      db
        .collection("fotos")
        .doc(FotoOverlay.fotoid)
        .get()
        .then((doc) => {
          if (!isCancelled) {
            setBronnaam(doc.data().bron_naam || null);
            setBronurl(doc.data().bron_url || null);
            setBronurltitel(doc.data().bron_url_titel || null);
            setLicentietitel(doc.data().licentie_titel || null);
            return storage
              .ref(`fotos/${FotoOverlay.fotoid}.jpg`)
              .getDownloadURL();
          }
        })
        .then((url) => {
          if (!isCancelled) {
            setUrl(url);
          }
        });

    return () => {
      isCancelled = true;
    };
  }, [FotoOverlay.fotoid]);

  useEffect(() => {
    licentietitel &&
      db
        .collection("licenties")
        .doc(licentietitel)
        .get()
        .then((doc) => {
          setLicentieurl(doc.data().url || null);
          setLicentietekst(doc.data().titel || licentietitel);
        });
  }, [licentietitel]);

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  return url ? (
    <div
      className="ShowFotoOverlay"
      style={{
        backgroundImage: `url(${url}),linear-gradient(to right, rgba(105,105,105,0.8) 0%,rgba(0,0,0,0.8) 25%, rgba(0,0,0,0.8) 75%, rgba(105,105,105,0.8) 100%)`,
      }}
      onClick={() => dispatchFotoOverlay({ type: "HIDE" })}
    >
      <span
        className="ShowFotoOverlayTekst"
        onClick={(e) => handleChildClick(e)}
      >
        <span style={{ fontSize: "0.7em" }}>{FotoOverlay.fotoid}</span>
        <br />({bronnaam},{" "}
        <a className="ShowFotoOverlayTekstLink" href={bronurl} target="_new">
          {bronurltitel}
        </a>
        ,{" "}
        <a href={licentieurl} target="_new">
          {licentietekst}
        </a>
        )
      </span>
    </div>
  ) : null;
};

export default ShowFotoOverlay;
