import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import { BrowserRouter } from "react-router-dom";
import { DeviceWidthProvider } from "./contexts/DeviceWidth";
import { UserProvider } from "./contexts/User";
import { FotoOverlayProvider } from "./contexts/FotoOverlay";

import moment from "moment";
import "moment/locale/nl";

import "leaflet/dist/leaflet.css";

moment.locale("nl");

ReactDOM.render(
  <BrowserRouter>
    <DeviceWidthProvider>
      <UserProvider>
        <FotoOverlayProvider>
          <App />
        </FotoOverlayProvider>
      </UserProvider>
    </DeviceWidthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
