import React, { createContext, useState, useEffect } from "react";
import { db } from "../firebase/Firebase";

export const IncidentenContext = createContext([]);

export const IncidentenProvider = ({ children }) => {
  const [incidenten, setIncidenten] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    let toState = [];
    db.collection("incidenten")
      .orderBy("datum")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty && !isCancelled) {
          snapshot.forEach((doc) => {
            if (!doc.data().te_verwijderen) {
              toState.push({ ...doc.data(), id: doc.id });
            }
          });
        }
      })
      .then(() => {
        if (!isCancelled) {
          setIncidenten(toState);
        }
      });
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <IncidentenContext.Provider value={incidenten}>
      {children}
    </IncidentenContext.Provider>
  );
};
