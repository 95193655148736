import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";

if (!firebase.apps.length) {
  firebase.initializeApp(require("./FirebaseConfig.json"));
}

firebase.firestore().settings({
  experimentalForceLongPolling: true,
});

// auth.setPersistence(auth.Auth.Persistence.SESSION)

export const db = firebase.firestore();

export const au = firebase.auth();

export const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
  twitterProvider: new firebase.auth.TwitterAuthProvider()
};

export const storage = firebase.storage();

export const ga = firebase.analytics();
